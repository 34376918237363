// Basic Colors
$primary: #2f6faa ; // Primary color
$darkPrimary: darken($primary, 20%);
$default: #c2c2c2; // Default color
$success: #1c84c6; // Success color
$info: #23c6c8; // Info color
$warning: #f8ac59; // Warning color
$danger: #ed5565; // Danger color

// Various colors
$text-color: #676a6c; // Body text
$gray: #f3f3f4; // Background wrapper color
$light-gray: #d1dade; // Default label, badge
$label-badge-color: #5e5e5e;
$light-blue: #f3f6fb;
$white: #ffffff;

//Statuses
$created: #804c97;
$in-triage: rgb(77, 164, 190);
$deferred: rgb(236, 149, 90);
$in-progress: rgb(34, 116, 70);
$closed-pending-approval: #999999;
$closed: #000000;
$planning: rgb(23, 78, 188);
$complete: rgb(235, 72, 22);

$statuses: (
  "Created": $created,
  "In_Triage": $in-triage,
  "Deferred": $deferred,
  "In_Progress": $in-progress,
  "Closed_Pending_Approval": $closed-pending-approval,
  "Closed": $closed,
  "Planning": $planning,
  "Complete": $complete
);

//Priority / Severity
$low: rgb(0, 122, 195);
$medium: rgb(12, 138, 74);
$high: rgb(238, 130, 25);
$critical: rgb(216, 54, 72);

$severities: (
  "Low": $low,
  "Medium": $medium,
  "High": $high,
  "Critical": $critical,
);

// Spinner color and margin
$spin-color: $primary;
$spin-margin: 0 auto;

//Basics
$basic-link-color: #337ab7;

// IBOX colors ( default panel colors)
$border-color: #e7eaec; // IBox border
$ibox-title-bg: #ffffff; // IBox Background header
$ibox-content-bg: #ffffff; // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: image-url("../img/patterns/shattered.png");

//Border radius for buttons
$btn-border-radius: 3px;

$visualizationColors: #007ac3, #0c8a4a, #ee8219, #d83648, #7300c1, #ca0081;
