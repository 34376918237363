$primary-color: #6086ac;
$primaryboder-color:#3575B1;
$secondary-color: #293846;
$menubg: #2f4050;
$extrasmall : 320px;
$small : 768px;
$medium: 991px;
.Risklogo{
  width: 150px;
}
.Risklogom{
  display:none;
}
.navbar-default{
 
  .sidebar-collapse{
    width: 100% !important;
  }
  .metismenu{
    ul{
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }
    li.bordernav:hover{
      border-left:none !important;
    }
    li{
      display: block;
      width: 100%;
      position: relative;
      border-left: 4px solid $menubg !important;
    &:hover{
      border-left: 4px solid $primary-color !important;
    }
     
      > a{
    color: #a7b1c2;
    font-weight: 600;
    padding: 7px 19px 7px 16px;
    display: block;
    text-align: left;
    > span.nav-label{
      padding-left: 12px;
    }

      }
       ul.nav-second-level{
         li{
          border-left: none !important;  
         a{
          padding-left: 45px;
         }
          &:hover{
            border-left: none !important;  
           a{
            padding-left: 45px;
           }
          }
         }
       }
    }
  
    li.nav-header{
      border-left: none !important;
      padding: 0;
      &:hover{
        border-left: none !important;
      }
    }
    li.active{
      border-left: 4px solid $primary-color !important;
      background: $secondary-color;
      ul.nav-second-level{
        li.active{
          border-left: 4px solid transparent !important;
          a{
            color: #FFF;
          }
        }
      }
    }
    .nav-second-level{
      li{
        a{
          padding-left: 34px;
          span.nav-label{
            padding-left: 0;
          }
        }
      }    }
   
  }
}
.mini-navbar{
  .sidebar-collapse{
    width: 70px !important;
  }
  .Risklogo{
    display:none;
  }
  .Risklogom{
    display:block;
  }
  .metismenu{
    li{
      width: 70px !important;
      a{
        font-size: 16px;
        text-align: center;
      }
      ul.nav-second-level{
        background: $menubg;
          position: absolute;
      left: 66px !important;
      top: 0;
      padding: 0;
      font-size: 12px;
      width: 160px;
      z-index: 2001 !important;
      > li{
           width: 160px !important;
   float: none;
   display: list-item;
   position: relative;
   border-left: 4px solid #2f4050 !important;
   &:hover{
    background: $secondary-color;
    border-left: 4px solid $secondary-color !important;
    padding-left: 14px;
    a{
      padding-left: 0;
    }
   }
   a{
    font-size: 12px;
text-align: left !important;
height: 40px;
padding-left: 14px;
line-height: 18px;
span{
  text-align: left !important;
  display: block !important;
}
      }
      
      }
      li.active{
        border-left: 4px solid $secondary-color !important;
        background: $secondary-color;
        &:hover{
          background: $secondary-color;
        }
      }
    
    }
  }
}
body.fixed-sidebar.mini-navbar{
  .navbar-default{
    .nav{
      ul{
      > li{
       > a{
          font-size: 13px;
          text-align: center;
        }
        ul.nav-second-level{
         > li{
            width: 160px;
    float: none;
    display: list-item;
    position: relative;
    a {
      span{
        padding: 7px 10px 7px 10px;
      }
    }
    }
          }
        }
      }
    }
    }
  }
}
.navbar-static-side {
  z-index: 99 !important;
}
