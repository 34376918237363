@import "../HIPAA";
.icon-back {
  &.yesCompliant {
    color: $yes-compliant;
  }
  &.yesPendingArtifacts {
    color: $yes-pending-artifacts;
  }
  &.yesPendingRequestedArtifacts {
    color: $yes-pending-requested-artifacts;
  }
  &.noGapIdentified {
    color: $no;
  }
  &.notApplicable {
    color: $not-applicable;
  }
  &.deferPendingResponse {
    color: $defer-pending-response;
  }
  &.clarify {
    color: $clarify-pending-response;
  }
  &.unanswered {
    color: $unanswered;
  }
}
.iconFront {
  color: white;
  &:hover {
    color: white;
  }
}
.statusDisplay {
  position: relative;
}
.statusText {
  padding-bottom: 5px;
}
