$high: red;
$medium: orange;
$low: gray;
@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}
:global .autoFade {
  @for $i from 0 to 10 {
    .group .stack svg svg rect:nth-child(#{$i}) {
      opacity: 1 / pow(1.4, $i - 1);
    }
  }
}
:global .groupedBarGraph {
  rect {
    fill: steelblue;
    stroke: white;
  }
  .group:nth-child(4n + 1) {
    rect,
    text {
      fill: #1970b1;
    }
  }
  .group:nth-child(4n + 2) {
    rect,
    text {
      fill: #1b9c9d;
    }
  }
  .group:nth-child(4n + 3) {
    rect,
    text {
      fill: #ea4a5c;
    }
  }
  .group:nth-child(4n + 0) {
    rect,
    text {
      fill: #f26e00;
    }
  }

  rect.Unanswered {
    fill: gray !important;
  }
  rect.Yes {
    fill: green !important;
  }
  rect.No {
    fill: red !important;
  }
}
