
@media only screen and (max-width: 800px) {
  #no-more-tables table, #no-more-tables thead, #no-more-tables tbody, #no-more-tables th, #no-more-tables tr, #no-more-tables td{
      display: block; 
    }
    #no-more-tables td:before { content: attr(data-title); }
  
    #no-more-tables table{
    tr{
      border: 1px solid #ccc;   
    }
    thead{
      tr{
        position: absolute;
        top: -9999px;
        left: -9999px; 
      }
    }
    td{
      border: none;
  border-bottom: 1px solid #eee; 
  position: relative;
  padding-left: 50%; 
  white-space: normal;
  text-align:left;
  min-height: 45px; 
  label{
    width: 100%;
  }
  &::before{
    position: absolute;
  top: 13px;
  left: 6px;
  width: 45%; 
  padding-right: 10px; 
  white-space: nowrap;
  text-align:left;
  font-weight: bold;
 

    }
  }

}
}
.badge-pill{
  border-radius: 6px !important;
}
.btn-outline-primary{border-radius: 4px !important;}
.rc-pagination-item-active{
  border-color: #3575B1 !important;
  background: #3575B1 !important;
  color: #FFF;
}
.rc-pagination-prev button:after, .rc-pagination-next button:after  {
  font-size: 23px;
  font-weight: bold;
  line-height: 16px;
  vertical-align: top;
}
