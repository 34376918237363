.aside {
  position: absolute;
  right: -16px;
  height: 96%;
  top: 150px;
}
.rightsideshow {
  height: 100%;
  padding-bottom: 80px;
}
.rightside {
  margin-right: -345px;
  position: relative;
  z-index: 9; /*2100*/
  transition-duration: 0.8s;
}
.rightsidecard {
  width: 345px;
  margin-left: 40px;
  border-radius: 0px 4px 4px 4px;
  background-color: #fff;
  padding: 15px 0 10px;
  overflow-y: scroll;
  height: 100%;
}
.rightsidecard .rightCard1.card {
  margin: 0 10px 15px !important;
}
.spin-icon {
  background: #3575b1 !important;
  position: absolute;
  padding: 7px 10px 7px 13px;
  border-radius: 20px 0 0 20px;
  font-size: 16px;
  top: 0px !important;
  left: 0;
  width: 40px;
  color: #fff;
  cursor: pointer;
}
.rightCard1.card {
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12) !important;
  .card-header {
    padding: 5px 10px 5px;
    font-size: 14px;
    vertical-align: middle;
    line-height: 28px;
    .btn-sm {
      font-size: 0.75rem;
    }
  }
  .right-height {
    /* max-height: 250px;
    overflow-y: scroll;*/
    margin: 15px 0 15px 2px;
  }
}
.form-check {
  float: left;
  width: 100%;
  padding-left: 0;
  .listresult {
    padding-top: 10px;
    .btn {
      color: #3575b1 !important;
      &active,
      &hover,
      &focus {
        color: #3575b1 !important;
      }
    }
  }
}
.filecard.card .card-header {
  padding: 10px 10px 10px;
}

@media (max-width: 500px) {
  .rightsidecard {
    width: 260px;
  }
  .rightside {
    margin-right: -260px;
  }
}
