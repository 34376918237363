/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.9.2
 *
*/

// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

// Variables, Mixins
@import "base/variables";
@import "base/mixins";
/*
// INSPINIA Theme Elements
@import "base/typography";
@import "base/glyphicons";
@import "base/navigation";
@import "base/top_navigation";
@import "base/buttons";
@import "base/badges_labels";
*/
@import "base/elements";
@import "base/sidebar";
@import "base/base";
@import "base/pages";
@import "base/chat";
@import "base/metismenu";
@import "base/spinners";

// Landing page
@import "base/landing";

// RTL Support
@import "base/rtl";

// For demo only - config box style
@import "base/theme-config";

// INSPINIA Skins
@import "base/skins";
@import "base/md-skin";

// Media query style
@import "base/media";

// Custom style
// Your custom style to override base style
@import "base/custom";

@import "style/index";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  body {
    overflow: visible !important;
  }

  #page-wrapper {
    margin: 0;
  }
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
