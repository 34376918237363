@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}
:global .progressBar {
  @for $i from 0 to 10 {
    rect:nth-child(#{$i}) {
      fill-opacity: #{1 / pow(1.4, $i - 1)};
    }
  }
}
