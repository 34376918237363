.navbar-static-side {
  z-index: 99 !important;
}
#group-dropdown{
  .dropdown-menu{
    max-height: 250px;
    overflow-y: scroll;
  }
}
.navbar ul {
  margin: 0;
  padding: 0;
  li{
    display: inline-block;
    vertical-align: top;
    padding: 10px 4px 10px;
  }
}

.nav.metismenu > li.navitem .profile-element, .nav.metismenu > li.navitem .organization-element{
  display: none !important;
}
.organization-name{
   margin-top: 7px;
  font-size: 14px;
}
.navbar.navbar-static-top {
  padding: 0;
  width: 100%;
  align-items: inherit;
  .dropdown-menu {
    right: 0;
    left: auto;
  }
}
.count-info {
svg{
  font-size: 18px;
  color: #3b4856;
}
.dropdown-item{
  padding: 0 !important;
  background-color: #FFFFFF !important;
  &:hover{
    background-color: #FFFFFF !important;
 }
}
.dropdown-menu {
  width: 300px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
}
}

.notif-dropdown{
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.notifcard{
  padding: 5px 8px;
  background-color: #FFF;
  border-bottom: 1px solid #c1c1c1;
  margin: 3px 0 7px;
  border-radius: 4px;
  min-height: 62px;
  &:hover{
    background-color:#f1f2f9;
  }
  a{
    padding: 0 !important;
  }
  p{
    font-size: 12px !important;
    color: #000;
    word-wrap: break-word;
    margin-bottom: 0 !important;
    a{
      display: block !important;
      font-size: 12px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 270px;
      padding: 0;
    }
    .notif-title{
      color: #5981a7 !important;
      font-weight: 600;
    }
  }
  
}


.notif-all.notifcard p, .notif-all.notifcard p a{
  font-size: 14px !important;
}
.notif-all{
  min-height: 64px;
  &:hover{
      background-color: #FFF !important;
    }
  }


.viewall {
  display: block;
  width: 100%;
  background-color: #5981a7;
  text-align: center;
  padding: 0 !important;
  height: 34px;
  color: #FFF;
  line-height: 30px;
  &:hover{
    color: #FFF !important;
  }
}

.notif-status{
  color: #333 !important;
  display: inline-block;
}
.dropdown-toggle::after{
  display: none;
}
.navbar-right .profile-element .btn-primary, .navbar-right .profile-element .btn-primary:hover, .navbar-right .profile-element .btn-primary:focus,
.navbar-right .profile-element .btn-primary:active,
.navbar-right .profile-element .btn-primary:not(:disabled):not(.disabled):active{
  background: transparent !important;
  border: none !important;
  padding: 0;
  color: #3b4856 !important;
  font-size: 14px;
  box-shadow: none !important;
}
.profile-element .dropdown-toggle::after {
  display: none;
}
.dropdown-item.active{
  background-color: #3575B1 !important;
}
.user-element .dropdown-toggle, 
.user-element .dropdown-toggle:focus, 
.user-element .dropdown-toggle:hover{
 box-shadow: none !important;
 background: transparent !important;
  border-color: transparent !important;
  color: #676a6c !important;
}
.user-element.show > .btn-primary.dropdown-toggle{
  box-shadow: none !important;
 background: transparent !important;
  border-color: transparent !important;
  color: #676a6c !important;
}
.left-nav{
  display: inline-block;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus{
  box-shadow: none !important;
}
@media only screen and (max-width: 991px) {
  
.user-element .dropdown-toggle{
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent !important;
  border-color: transparent !important;
  color: #676a6c;
}

}
@media only screen and (max-width: 800px) {
 
  .group-element .dropdown-toggle::after, .organization-element .dropdown-toggle{
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  }
  @media (max-width: 850px) {
    
    ul.navbar-top-links{
      float: right;
    }
    .nav.navbar-top-links a{
      padding: 5px;
    }
  .count-info{
    padding: 5px !important;
    width: 35px;
  }
  

  .navbar.navbar-static-top{
    padding-right: 10px !important;
    display: inline-block !important;
  }
}
@media only screen and (max-width:500px){
 
  .navbar-top-links .dropdown-alerts, .count-info .dropdown-menu{
    width: 240px;
  }
  
  .group-element .dropdown-toggle, .organization-element .dropdown-toggle{
    width: 78px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}