@import "./palette";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
.btn {
  padding: 4px 8px;
  font-size: 1em;
}
.btn-link {
  padding: 0px;
}
.mt-205 {
  margin-top: 5px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
$cardBorderRadius: 15px;
.mt-6 {
  margin-top: 3.5rem;
}
.card {
  @extend .shadow-sm;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: $cardBorderRadius;
  .card-header {
    border-top-left-radius: $cardBorderRadius;
    border-top-right-radius: $cardBorderRadius;
  }
  .card-footer {
    border-bottom-left-radius: $cardBorderRadius;
    border-bottom-right-radius: $cardBorderRadius;
  }
}

.card,
.transparent-card {
  .card-header {
    font-size: 1.1em;
    padding: 10px 25px 10px 15px;
    font-weight: 600;
    .btn,
    a {
      font-size: $font-size-sm;
    }
    .nav-item {
      padding: 0px;
      height: auto;
      a {
        padding: 2px 8px;
      }
    }
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff !important;
  background-color: #7fa5cb !important;
  font-size: 0.75rem;
}

input.placeholder-right::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: right;
}

.breadcrumb {
  background-color: transparent !important;
}
.dashboard-header,
.fair-sidebar {
  .nav-tabs .nav-link,
  .nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important;
    &.active {
      color: $primary;
      border: none;
      border-bottom: 3px solid $primary;
      font-weight: bold;
    }
  }
}

.footable {
  th {
    border-bottom: none !important;
  }
  tr:first-of-type td {
    // padding-top: 25px;
    // border-top: none;
  }
}

.modal {
  .modal-body {
    max-height: 70vh;
    overflow-y: scroll;
  }
}
.cancel-btn {
  background: #8f8f8f;
  color: #fff;
  &:hover,
  &:active,
  .active {
    background: #838383;
    color: #fff;
  }
}
.dropdown-item {
  font-size: 13px !important;
}
.input-group-append {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 1px solid #e5e6e7;
}
.form-control:focus,
.single-line:focus {
  border-color: #0d8ddb !important;
  border-radius: 0 !important;
}
.formInput {
  border-radius: 0 !important;
}
.input-group,
.sortableUI .btn-group {
  vertical-align: top;
}

.btn-group .dropdown-toggle.btn-primary {
  height: 30px !important;
}
.table td .ProseMirror {
  padding: 0 !important;
}
.attachThreatsModal {
  .modal-dialog {
    max-width: 900px !important;
  }
}
.modal {
  .modal-dialog {
    max-width: 600px;
  }

  .modal-body {
    max-height: 100% !important;
    overflow-y: inherit !important;

    .form-control {
      border: 1px solid #e5e6e7;
      color: #9fa2b4;
      border-radius: 4px;
    }
    .form-label {
      color: #000;
      margin-bottom: 2px;
      // font-weight: bold;
    }
  }
  .modal-body-scroll {
    max-height: calc(100vh - 200px) !important;
    overflow-y: auto !important;
  }
  .modal-footer {
    .btn-light {
      color: #fff;
      background-color: #8f8f8f;
      border-color: #8f8f8f;
    }
  }
}
.card {
  border-radius: 4px !important;
  .card-header span.h5 {
    font-size: 0.9375rem;
    font-weight: 600 !important;
    margin-top: 10px;
  }
}
.ibox-content {
  .form-group {
    margin-bottom: 0.2rem;
    .form-label {
      margin-bottom: 0;
    }
  }
}
.css-1s2u09g-control {
  height: 40px;
  line-height: 30px;
}
.ibox-content.dragAndDropContainer {
  max-height: 315px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.dragAndDropContainer {
  label {
    line-height: 25px;
  }
}
.btn-outline-primary {
  color: #3575b1 !important;
  border-color: #3575b1 !important;
  border-radius: 0;
  &:hover {
    background-color: #3575b1 !important;
    border-color: #3575b1 !important;
    color: #fff !important;
  }
  &:focus {
    background-color: #3575b1 !important;
    border-color: #3575b1 !important;
    color: #fff !important;
  }
  &:active {
    background-color: #3575b1 !important;
    border-color: #3575b1 !important;
    color: #fff !important;
  }
}
.input-group-prepend .btn.btn-light {
  border-radius: 0.25rem !important;
  margin-right: 8px !important;
}

.btn-lg,
.btn-group-lg > .btn {
  border-radius: 0.25rem !important;
}
.dropdown-toggle.btn-outline-primary {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.card .card-header .dropdown-toggle.btn-primary {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
  color: #fff !important;
  background: #2f6faa !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
/**********Checkbox************/
.custom-control-label {
  margin-bottom: 0;
  padding-left: 5px !important;
  padding-top: 4px !important;
}

.custom-checkbox {
  margin-bottom: 0.8rem;
  z-index: 0 !important;
  .custom-control-label::before {
    height: 1.2rem !important;
    width: 1.2rem !important;
    border: 1px solid #6c93ba;
  }
  .custom-control-input {
    height: 28px !important;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #6b92b9;
    border: 1px solid #6c93ba;
    width: 1.2rem !important;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='28' height='22' viewBox='0 0 28.412 22.855'%3e%3cpath id='check' d='M22.6,3.8,8,19.4,1.4,11.7,0,12.9l7.9,9.3L24,5.1Z' transform='translate(0.703 -3.095)' fill='%23ffffff' stroke='%23ffffff' stroke-width='3'/%3e%3c/svg%3e ");
  }
  .custom-control-label::after {
    top: 0.3rem;
    left: -1.5rem;
    height: 1.2rem !important;
    width: 1.2rem !important;
  }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: rgb(233, 236, 239);
    border: 1px solid #6b92b9;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='28' height='22' viewBox='0 0 28.412 22.855'%3e%3cpath id='check' d='M22.6,3.8,8,19.4,1.4,11.7,0,12.9l7.9,9.3L24,5.1Z' transform='translate(0.703 -3.095)' fill='%239ca0b1' stroke='%239ca0b1' stroke-width='3'/%3e%3c/svg%3e ");
  }
}
.custom-radio {
  margin-top: 8px;
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #fff;
    border: 2px solid #6b92b9;
  }

  .custom-control-label::after {
    top: 0.5rem;
    left: -20px;
    width: 0.9rem !important;
    height: 0.9rem !important;
  }
  .custom-control-label::before {
    width: 1.4rem !important;
    height: 1.4rem !important;
  }
  .custom-control-input {
    width: 1.4rem !important;
    height: 1.4rem !important;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
    background-color: #6b92b9;
    border-radius: 50%;
    border: 2px solid white;
  }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
    border: 2px solid #dee2e6;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::after {
    background-color: #9ca0b1;
    background-image: none;
    border-radius: 50%;
    border: 2px solid #e9ecef;
  }
}
.launcher-button {
  background-color: #3575b1 !important;
}
.popover-help {
  max-width: 500px;
  max-height: 150px;
  overflow-y: scroll;
  z-index: 1000000;
  bottom: -30% !important;
}
.footable-loaded .ProseMirror .text-scroll {
  max-height: 200px;
  overflow-y: auto;
}
.mb-10 {
  margin-bottom: 0.625rem;
}

@media (max-width: 850px) {
  #content-wrapper {
    margin-top: 0 !important;
  }
  .footer.fixed {
    position: relative !important;
    margin-left: 0 !important;
  }
  .modal {
    padding-left: 0 !important;
  }
  .modal-dialog {
    margin: 3rem auto 4rem;
  }
  .popover-help {
    bottom: -36% !important;
    max-width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .navbar-top-links .dropdown-alerts {
    margin-left: 0 !important;
  }

  .rbc-btn-group {
    margin-top: 5px;
  }
  .nav-tabs > li > a {
    padding: 14px 20px 10px;
  }
}

@import "./theme/Inspinia/style.scss";
