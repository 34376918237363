@import "../../_palette.scss";

.badge {
  cursor: default;
  margin: 2px;
  &.Created,
  &.Pending {
    background-color: $white;
    color: $created;
    border: 1px solid $created;
  }
  &.In_Triage {
    background-color: $white;
    color: $in-triage;
    border: 1px solid $in-triage;
  }
  &.Deferred {
    background-color: $white;
    color: $deferred;
    border: 1px solid $deferred;
  }
  &.In_Progress {
    background-color: $white;
    color: $in-progress;
    border: 1px solid $in-progress;
  }
  &.Closed_Pending_Approval {
    background-color: $closed-pending-approval;
    color: $white;
    border: 1px solid $closed-pending-approval;
    svg {
      color: $white;
    }
  }
  &.Closed {
    background-color: $closed;
    color: $white;
    border: 1px solid $closed;
    svg {
      color: $white;
    }
  }
  &.Planning {
    background-color: $white;
    color: $planning;
    border: 1px solid $planning;
  }
  &.Complete {
    background-color: $white;
    color: $complete;
    border: 1px solid $complete;
  }
  &.Low {
    background-color: white;
    color: $low;
    border: 1px solid $low;
  }
  &.Medium,
  &.Minor {
    background-color: white;
    color: $medium;
    border: 1px solid $medium;
  }
  &.High,
  &.Major {
    background-color: white;
    color: $high;
    border: 1px solid $high;
  }
  &.Critical {
    background-color: $critical;
    color: white;
    border: 1px solid $critical;
  }
  &.releted_item {
    cursor: pointer;
  }
}
// Define a map of colors
$colors: (
  IntakeComplete: $created,
  IntakeIncomplete: $created,
  TriagePrepIncomplete: $in-triage,
  TriagePrepComplete: $in-triage,
  Deferred: $deferred,
  Act: $in-progress,
  Monitor: $closed,
);

// Loop through the list and create CSS rules
@each $name, $color in $colors {
  .#{$name}-normal {
    background-color: $white;
    color: $color;
    border: 1px solid $color;
  }
  .#{$name}-filled {
    background-color: $color;
    color: $white;
    border: 1px solid $color;
  }
}
