@import "../../../palette";
.btn-primary-invert {
  background-color: white;
  border: solid 1px $primary;
  > i {
    color: $primary !important;
  }
}
.searchBar {
  position: relative;
  z-index: 100;
  display: grid;

  .form-control {
    float: none;
    position: relative;
    border-top-right-radius:0 !important;
    border-bottom-right-radius:0 !important;
  }

  &active {
    .results {
      display: block;
    }
  }
}
.input-group:not(.has-validation) > .form-control:not(:last-child){
  border-top-right-radius:0 !important;
    border-bottom-right-radius:0 !important;
}
.resultHolder,
.addNew {
  position: relative;
  z-index: 3;

  .addNew {
    width: 300px;
    right: 0px;
    .form-control {
      float: default;
      position: relative;
      z-index: 3;
    }
  }
}

.results {
  //display: none;
  width: 100%;
  label {
    padding: 5px;
    i {
      padding-left: 10px;
      padding-right: 10px;
      transform: scale(1.5, 1.5);
    }
  }
}

.popout {
  width: 100%;
  position: absolute;
  border: 1px solid gray;
  border-top: none;
  box-shadow: 3px 3px 3px lightgray;
  background-color: white;
  padding: 10px;
  z-index: 3;
}
