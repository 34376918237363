@import "../../_palette.scss";

.stats-bar {
  @each $key, $value in $severities {
    &.#{$key} {
      rect {
        fill: $value !important;
      }
      .status-label {
        fill: $value;
      }
    }
  }
  @each $key, $value in $statuses {
    &.#{$key} {
      rect {
        fill: $value !important;
      }
      .status-label {
        fill: $value;
      }
    }
  }
}
