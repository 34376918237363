.box-plot-graph {
  .domain-axis ~ g:nth-of-type(3n + 1) {
    .label-alternate {
      transform: translate(0px, -5px);
    }
  }
  .domain-axis ~ g:nth-of-type(3n + 2) {
    .label-alternate {
      transform: translate(0px, 5px);
    }
  }
  .domain-axis ~ g:nth-of-type(3n) {
    .label-alternate {
      transform: translate(0px, 15px);
    }
  }
}
