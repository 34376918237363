$unanswered: #c4c4c4;
$yes: #22977e;
$no: #ea4a5c;
$na: #676a6c;
$defer: #237cb7;
$clarify: #f8ac59;

$yes-compliant: $yes;
$yes-pending-artifacts: #2bbf9f;
$yes-pending-requested-artifacts: #38e3e3;

$no-gap-identified: $no;

$not-applicable: $na;

$defer-pending-response: $defer;
$defer-pending-reassignment: #30abfc;

$clarify-pending-response: $clarify;
