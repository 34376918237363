.tableComparison {
  .buffer {
    height: 40px;
  }

  .dock {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60%;
    background: #3575B1;
    color: white;
    border: solid 2px white;
    border-radius: 40px;
    padding: 15px;
    vertical-align: middle;
    box-shadow: white 0px 0px 10px; 
    font-size: 14px;
    z-index: 9;
    .btn-link {
      color: white;
    }
    .float-right .btn {
      padding: 10px;
      border-radius: 30px;
    }
  }
}
