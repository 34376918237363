/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.card-header {
  background: $white;
}
